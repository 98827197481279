.user__header{
    background: linear-gradient(87deg, rgb(var(--gradient-left)) -20%, rgb(var(--gradient-right)) 100%) !important;
    position: relative;
    height:  600px;
    
}
.user__header > img{
    position: absolute;
    height: 670;
    opacity: 0.2;
    top: -70px;
}