:root {
  --primary: 157, 78, 221;
  --dark: 33, 35, 47;
  --danger: 214, 40, 40;
  --success: 6, 214, 160;
  --white: 245, 246, 247;
  --default: 255, 255, 255;
  --gradient-left: 19,138,246,255;
  --gradient-right: 77,169,253,255;
  --active: 17, 113, 239;
  --dark-max: 0, 0, 0;
  --blue: #5e72e4;
}
#root {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  position: fixed;
}
.bg-default, .bg-default > *{
  background: rgb(var(--default))!important;
}
.bg-primary {
  background-color: rgb(var(--primary));
}
*{
  scroll-behavior: smooth;
}
.text-primary {
  color: rgb(var(--primary));
}
.text-white{
  color:  rgb(var(--default));
}
.btn-primary {
  background-color: #5e72e4;
  border-color: #5e72e4;
}
.overflow-auto {
  overflow-y: auto;
}
body,
html {
  background-color: rgb(var(--default));
}

header {
  background: linear-gradient(87deg, rgb(var(--gradient-left)) 0, rgb(var(--gradient-right)) 100%) !important;
  box-shadow: 0px 1px 3px 0px rgba(var(--dark-max),0.12),
  0px 1px 2px 0px rgba(var(--dark-max),0.24);
  max-height: 100px;
}

header > div {
  cursor: pointer;
  transition: 0.5s;
  padding: 10px 20px;
}
header img {
  width: 50px;
}

header span:not(.mdi) {
  margin-bottom: -10px;
}

header span.mdi {
  font-size: 20px;
  margin-left: 10px;
}

header small {
  display: block;
  margin-top: -20px;
}

sidebar {
  background: #263544;
}

sidebar ul {
  margin: 0;
  padding: 0;
}

sidebar ul li {
  list-style: none;
  width: 100%;
}

sidebar ul li a {
  width: 100%;
  list-style: none;
  color: rgb(var(--white));
  opacity: 0.6;
  display: flex;
  align-items: center;
  padding: 5px 0;
  margin: 2.5px 0;
  transition: 0.5s;
  border-radius: 5px;
}

sidebar ul li a.active {
  opacity: 1;
  background-color: rgba(var(--active), 0.4);
}

sidebar ul li a.active .mdi {
  color: #5e72e4;
}

sidebar ul li a:visited,
sidebar ul li a:focus {
  color: rgb(var(--white));
  text-decoration: none !important;
}

sidebar ul li a:hover {
  background-color: rgba(var(--active), 0.4);
  cursor: pointer;
  transition: 0.5s;
  text-decoration: none;
  color: rgb(var(--white)) !important;
  opacity: 1;
}

sidebar ul li span.mdi {
  font-size: 25px;
  margin: 0 15px;
}

sidebar ul li text {
  font-size: 14px;
}
input[type="email"],
input[type="email"]:active,
input[type="email"]:disabled,
input[type="email"]:enabled,
input[type="email"]:hover{
  text-transform: lowercase;
}
.chip {
  display: inline-flex;
  align-items: center;
  padding: 5px 10px 5px 5px;
  height: 35px;
  font-size: 16px;
  line-height: 50px;
  border-radius: 25px;
  background-color: #2f3243;
  margin-right: 5px;
  margin-bottom: 5px;
  color: rgb(var(--white));
  zoom: 0.7;
}

.chip:hover {
  cursor: pointer;
  background: rgba(var(--dark-max), 0.9);
}

.chip img {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  margin-right: 5px;
}

.rs-steps-item-description {
  margin-top: 0 !important;
  font-style: italic;
}

.rs-drawer-body {
  overflow-x: hidden !important;
}
input[type=file]{
    font-size: 0px!important;
}
input[type=file]::-webkit-file-upload-button {
    border: none;
    height: 90px;
    width: 90px;
    cursor:  pointer;
    margin:  0;
    position: relative;
    z-index: 2!important;
    opacity: 0;
}
a{
  cursor: pointer!important;
  text-decoration: none!important;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: rgb(var(--default));
}
::-webkit-scrollbar-thumb {
  background: rgb(var(--gradient-right));
}
::-webkit-scrollbar-thumb:hover {
  background: rgb(var(--gradient-left));
}
.rs-table-row{
  height: 200px !important;
  position: relative!important;
  margin-left: -40px;
}
.rs-table-row img{
  width: 100%;
  height: 200px;
  border-radius: 8px;
  margin: auto;
  
}
.rs-table-row-header{
  display: none!important;
  
}
.rs-table-cell-content {
  padding: 5px 10px!important;
  font-size: 14px;
  margin: 0;
  height: 200px!important;
  white-space: inherit!important;
  
}
.rs-table-cell-first{
  height: 200px!important;
  
}
.rs-table-cell{
  height: 200px!important;
  
}
.validacao{
  color: rgb(var(--danger))!important;
  font-weight: 500;
  margin-left: 5px;
}
.main-header-area.sticky{
  box-shadow: 0px 3px 16px 0px rgb(0 0 0 / 10%);
  position: fixed;
  width: 100%;
  top: -70px;
  left: 0;
  right: 0;
  z-index: 990;
  transform: translateY(70px);
  transition: transform 500ms ease, background 500ms ease;
  -webkit-transition: transform 500ms ease, background 500ms ease;
  box-shadow: 0px 3px 16px 0px rgb(0 0 0 / 10%);
  background: rgba(255, 255, 255, 0.96);
  background: linear-gradient(87deg, rgb(var(--gradient-left)) 0, rgb(var(--gradient-right)) 100%) !important;
}
.animate__animated{
  animation-duration: 1s!important;
  animation-delay: 0s!important;
}
header img {
  border-radius: 0!important;
}
@media (min-width: 0px) and (max-width: 1400px){
  .header-area .main-header-area {
    padding: 25px 0px;
    background: transparent;
  }

}
.avatar{
  border-radius: 20%!important;
  padding: 2px;
  background:  #FFF;
  width: 48px;
  height: 48px;
  border: none;
}
.main-header-area.sticky{
  box-shadow: none!important;
}
.servico-label{
  color: var(--default);
}
