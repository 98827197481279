.profile .img__form{
    position: relative !important;
    padding: 0;
    cursor:  pointer;
}
.profile .img__form input[type=file]{
    font-size: 0px!important;
    position: absolute;
    left: calc(50% - 90px)!important;
    transform: translateY(-30%);
    width: 180px;
    z-index: 2;
}
.profile .img__form input[type=file]::-webkit-file-upload-button {
      border: none;
      height: 160px;
      width: 160px;
      cursor:  pointer;
      margin:  0;
      border-radius: 50%;
      opacity: 0;
}
.profile .img__form .input-img-img{
    background:  #FFF;
    border: none;
    width: 160px;
    height: 160px;
    border-radius: 20%;
    position: absolute;
    left: 50%;
    z-index: 1!important;
    padding: 5px;
}

  .fa-question-circle {
    color: #3498db;
    font-size: 14px;
    margin-left: 5px;
  }

  
  .heading-small {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .custom-tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip-text {
    visibility: hidden;
    width: 200px;
    background-color: #5e72e4;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
  }

  .custom-tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }
  