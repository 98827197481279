
.servico__titulo{
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 0;
    padding-top: 0;
}
.servico__titulo::first-letter{
    text-transform: uppercase;
  }
.servico__descricao{
    width: 100%;
    font-size: 15px;
    font-weight: 500;
    color: #c1bfc4;
    
  
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; 
}
.servico__sub__item{
    margin-top: 10px;
}
.servico__sub__item p, label{
    margin: 0;
}
.servico__sub__item label{
    font-size: 12px;
    font-weight: 500;
}
.servico__sub__item p{
    font-size: 14px;
    color: #c1bfc4

}