.input__img{
    position: relative !important;
    padding: 0;
    cursor:  pointer;
    left: calc(50% - 45px);
    width: 100px;
    height: 100px;
}
.input__img__img{
    background:  #FFF;
    border: none;
    height: 100px;
    width: 100px;
    border-radius: 20%;
    position: absolute;
    left: 0;
    z-index: 1!important;
    padding: 5px;
}

  .form-control-label-img {
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    color: #778899;
    
  }

  

  