.banco-de-imagens .imagem{
  position: relative;
}
.banco-de-imagens .imagem img{
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}
.banco-de-imagens .imagem span{
    position: absolute;
    display: none;
}
.banco-de-imagens .imagem:hover span{
    display: block;
    font-size: 14px;
    font-weight: bold;
    top: calc(50% - 7px);
    left: 41%;
    color: white;
    cursor: pointer;
}