.convenios .item .column{
    min-width: 110px;
}

.convenios .item img {
    border-radius: 10px;
    height: 70px;
    width: 90px;
    border: 1px solid #f1f1f1;
  }
  
.convenios .item{
    padding: 10px 0;
    padding: 10px 0;
    align-items: center;
}
.convenios .item:hover {
    background-color: #f2f2f2;
  }
.convenios .item button {
    width: 80px;
    height: 35px;
    padding: 0;
    font-weight: 400;
    font-size: 14px;
    margin-left: auto;
}